.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

table{
  color: #282c34;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.progressbar {
  width: 1000px;
  height: 10px;
  background-color: white;
  margin-bottom: 50px;
}

.progressbar div {
  width: 33.3%;
  height: 10%;
  background-color: rgb(98, 0, 255);
}

.image-item {
  display: flex;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.logo {
  background-image: url('https://valuaciones.cl/wp-content/uploads/2022/04/bg-slider-top.jpg');

  height: 900px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbbbbb!important;
    font-size:18px!important;
}

.formcontainer2 {
  max-width: 400px;
  padding: 2px;
  background: #0866c6;
  opacity: .85;
  border-radius: 8px;
}